/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useMemo, useState } from "react";

import classNames from "classnames";
import { Collapse } from "reactstrap";

import Icon from "components/common/Icon";

const nodeIndentsMap = {
  0: 0,
  1: 11,
  2: 27,
};

const TreeNode = ({
  id,
  title,
  nodes,
  nestingLevel,
  selectedNode,
  onCheckboxChange,
  onNodeSelect,
  checked,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleCollapse = () => setIsOpen((prev) => !prev);

  const isLeaf = !nodes?.length && nestingLevel !== 0;

  const marginLeft = useMemo(() => {
    if (!nestingLevel) return 0;

    if (typeof nodeIndentsMap[nestingLevel] === "number") return nodeIndentsMap[nestingLevel];

    return nestingLevel - 1 * 27;
  }, [nestingLevel]);

  const handleOnChange = async () => {
    if (!onCheckboxChange) return;

    await onCheckboxChange(id, !checked);
  };

  if (!isLeaf) {
    return (
      <div
        className={classNames("tree-node tree-node-collapse", {
          "parent-node": nestingLevel === 0,
          highlighted: id === selectedNode?.section,
        })}
        style={{ marginLeft }}
      >
        <div className="tree-node-title">
          <div className="custom-control custom-checkbox">
            <input
              id={title}
              className="custom-control-input"
              onChange={handleOnChange}
              disabled={disabled}
              type="checkbox"
              checked={checked}
            />
            <label className="custom-control-label" onClick={() => onNodeSelect(id)}>
              <span>{title}</span>
            </label>
          </div>
          <Icon name={`arrow-${isOpen ? "up" : "down"}`} onClick={toggleCollapse} />
        </div>
        <Collapse isOpen={isOpen}>
          <div className="collapse-content">
            {(nodes || []).map((node) => (
              <TreeNode
                key={node.key}
                {...node}
                onCheckboxChange={onCheckboxChange}
                selectedNode={selectedNode}
                onNodeSelect={onNodeSelect}
              />
            ))}
          </div>
        </Collapse>
      </div>
    );
  }

  return (
    <div
      className={classNames("tree-node node-leaf", { highlighted: id === selectedNode?.section })}
      style={{ marginLeft }}
    >
      <div className="tree-node-title">
        <div className="custom-control custom-checkbox">
          <input
            id={title}
            className="custom-control-input"
            onChange={handleOnChange}
            disabled={disabled}
            type="checkbox"
            checked={checked}
          />
          <label className="custom-control-label" onClick={() => onNodeSelect(id)}>
            <span>{title}</span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default TreeNode;
