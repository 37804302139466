import { isArray, isDate, isObject, isUndefined } from "lodash";

const assignObjectValuesToSet = (object = {}) => {
  const assigned = Object.entries(object).map(([key, value = null]) => {
    if ((!isDate(value) && (isObject(value) || isArray(value))) || isUndefined(value)) {
      return [key, value];
    }

    return [key, { set: value }];
  });

  return Object.fromEntries(assigned);
};

export default assignObjectValuesToSet;
