/* eslint-disable react/jsx-no-useless-fragment */
import React, { Fragment, useEffect, useState } from "react";

import { Col, Row } from "reactstrap";

import CustomHelmet from "components/calculations/components/CustomHelmet";
import Button from "components/common/Button";
import Modal from "components/common/Modal";
import PrecedentsTree from "components/precedentsTree/PrecedentsTree";

import useModal from "hooks/useModal";

import { titleCase } from "helpers/titleCase";

export const DOCUMENT_SECTIONS_MODAL_NAME = "CLIENT_DOCUMENT_SECTIONS";

const convertTreeToNestedFormat = (tree) => {
  function processNodes(nodes) {
    if (!nodes) return [];

    return nodes.flatMap((node) => [
      { section: node.id, hidden: !node.checked },
      ...processNodes(node.nodes),
    ]);
  }

  return processNodes(tree.nodes);
};

const generateTree = (args) => {
  const { sections = [] } = args;

  const sortedSections = [...sections].sort((a, b) => {
    const aParts = a.section.split(".").map((part) => parseInt(part, 10) || 0);
    const bParts = b.section.split(".").map((part) => parseInt(part, 10) || 0);

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
      const aPart = aParts[i] || 0;
      const bPart = bParts[i] || 0;

      if (aPart !== bPart) {
        return aPart - bPart;
      }
    }
    return 0;
  });

  const parseSectionName = (bookmarkSection) => {
    const { label, section, tag, hidden } = bookmarkSection;

    const numbers = section.split(".").map((s) => Number.parseInt(s, 10));

    return {
      section,
      label,
      numbers,
      tag,
      hidden,
    };
  };

  const parsedSections = sortedSections.map((bn) => parseSectionName(bn));

  const genLevelNodes = (level, current) => {
    const filteredSections = parsedSections.filter(
      (b) => b.numbers.length === level + 1 && current.every((num, idx) => b.numbers[idx] === num),
    );

    if (filteredSections.length === 0) return null;

    const isRoot = level === 0;

    const nodes = filteredSections.map((sectionInfo) => {
      const { label, numbers, section, hidden } = sectionInfo;

      const childNodes = genLevelNodes(level + 1, numbers);

      const title = (() => {
        const isNumeralSection = section.replace(/[0-9.]/g, "").length === 0;

        if (isNumeralSection) {
          return `${section}${isRoot ? "." : ""} ${titleCase(label)}`;
        }

        return `${titleCase(label)}`;
      })();

      const node = {
        title,
        checked: !hidden, // || !(isRoot && !childNodes),
        key: label,
        id: section,
        nestingLevel: level,
        nodes: childNodes,
        className: isRoot ? "parent-node" : undefined,
      };

      return node;
    });

    return nodes;
  };

  const nodes = genLevelNodes(0, []);

  return {
    nodes,
  };
};

const ClientDocumentsSectionsModal = () => {
  const { options, close } = useModal(DOCUMENT_SECTIONS_MODAL_NAME);

  const { sections, submit: createDocument } = options || {};

  const [tree, setTree] = useState([]);
  const [node, setNode] = useState(null);

  useEffect(() => {
    if (sections) {
      setTree(generateTree({ sections: sections || [] }));
      setNode(sections[0]);
    }
  }, [sections]);

  const onChange = async (key, val) => {
    const parseKeyToNumbers = (key) => key.split(".").map((s) => Number.parseInt(s, 10));

    const keyNumbers = parseKeyToNumbers(key);

    const setNode = (node) => {
      const nodeNumbers = parseKeyToNumbers(node.id || "");

      const isNested = keyNumbers.every((num, idx) => nodeNumbers[idx] === num);

      const updatedNode = {
        ...node,
        checked: isNested ? val : node.checked,
        nodes: node.nodes?.map((n) => setNode(n)),
      };

      if (!isNested && node.nodes) {
        const isAnyChildChecked = node.nodes.some((n) => n.checked);
        updatedNode.checked = isAnyChildChecked;
      }

      return updatedNode;
    };

    const updateParentNodeState = (node) => {
      if (node.nodes) {
        const updatedNodes = node.nodes.map(updateParentNodeState);
        const isAnyChildChecked = updatedNodes.some((n) => n.checked);
        if (isAnyChildChecked) {
          node.checked = true;
        }
        node.nodes = updatedNodes;
      }
      return node;
    };

    const updatedTree = setNode(tree);

    setTree(updateParentNodeState(updatedTree));
  };

  const handleSubmit = () => {
    const sections = convertTreeToNestedFormat(tree);

    createDocument(sections);

    close();
  };

  const handleSetNode = (id) => {
    if (node.section === id) return null;

    setNode(sections.find((section) => section.section === id));
  };

  return (
    <Modal
      size="md"
      name={DOCUMENT_SECTIONS_MODAL_NAME}
      title="Select Sections"
      noFooter
      showCloseButton
      className="client-document-sections-form"
    >
      <CustomHelmet
        title="Document Sections"
        description="Document Sections"
        href="https://www.divorcepath.com/app/[new-document]"
        isCanonical
      />
      <Fragment>
        <Row>
          <Col sm={7}>
            <div className="sections-modal-content left">
              <PrecedentsTree
                tree={tree}
                selectedNode={node}
                onCheckboxChange={onChange}
                onNodeSelect={handleSetNode}
              />
            </div>
          </Col>
          <Col sm={5}>
            <div className="sections-modal-content right">
              <div className="section-description-content">
                <div className="section-title">
                  {node?.section &&
                    `${node?.section}${node?.section?.includes(".") ? "" : "."} ${node?.label}`}
                </div>
                <div className="section-description">
                  {node?.description || "No description yet."}
                </div>
              </div>
              <div className="sections-modal-footer">
                <Button size="sm" onClick={handleSubmit} className="btn-submit">
                  Confirm
                </Button>
                <Button size="sm" color="secondary" onClick={close} className="btn-cancel">
                  Cancel
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Fragment>
    </Modal>
  );
};

export default ClientDocumentsSectionsModal;
