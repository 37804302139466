import React from "react";

import TreeNode from "components/precedentsTree/TreeNode";

const PrecedentsTree = ({ tree, selectedNode, onCheckboxChange, onNodeSelect }) => {
  return (
    <div className="tree-wrapper">
      {tree.nodes?.map((node) => (
        <TreeNode
          checked={node.checked}
          key={node.key}
          onCheckboxChange={onCheckboxChange}
          {...node}
          selectedNode={selectedNode}
          onNodeSelect={onNodeSelect}
        />
      ))}
    </div>
  );
};

export default PrecedentsTree;
